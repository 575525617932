/* eslint-disable no-nested-ternary */
import { Grid, IconButton, Paper, Stack } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { ILineItemHeader } from '../LineItems/types';
import { LineItemsGrid, LineItemsGridRef } from '../LineItems';

export interface CustomDetailPanelProps {
    isOpen: boolean;
    recordId: number;
    recordTypeId: number;
    headers: ILineItemHeader;
    onActiveEditing: any;
}

const CustomDetailPanel = ({ isOpen, recordId, recordTypeId, headers, onActiveEditing }: CustomDetailPanelProps) => {
    const gridRef = useRef<LineItemsGridRef | null>(null);
    const apiRef = useGridApiContext();
    const [panelWidth, setPanelWidth] = useState(apiRef.current.rootElementRef?.current?.clientWidth || 0);

    const handleClickAdd = () => {
        gridRef.current?.handleAddClick();
    };

    // useEffect(() => {
    //     onActiveEditing(isEditing);
    // }, [isEditing, onActiveEditing]);

    // To resize the panel
    useEffect(() => {
        let observer: null | ResizeObserver = null;

        if (apiRef?.current?.rootElementRef?.current) {
            observer = new ResizeObserver((e) => {
                setPanelWidth(e[0].target.clientWidth);
            });

            observer.observe(apiRef.current.rootElementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [apiRef]);

    return (
        <Stack sx={{ display: 'flex', height: '100%', maxWidth: panelWidth || 'inherit' }}>
            <Paper elevation={1} component={Grid} container sx={{ height: '400px', m: '20px', p: '20px', flexGrow: 1 }}>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={10} sx={{ height: '360px' }}>
                        <LineItemsGrid ref={gridRef} isOpen={isOpen} recordId={recordId} headers={headers} recordTypeId={recordTypeId} />
                    </Grid>
                    <Grid item xs={2} sx={{ maxWidth: '100px !important' }}>
                        <IconButton onClick={handleClickAdd} sx={{ mb: 1 }} color="secondary" aria-label="add new row">
                            <AddCircleOutline />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

export default CustomDetailPanel;
