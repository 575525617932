import jwt_decode from 'jwt-decode';
import { IUserDataToken } from 'utils/types';
import { DATE_BASE_FIELDS, FilterDateCriteriaEnum, IRecordField, USER_BASE_FIELDS } from 'ui-component/records/types';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { CUSTOM_FILTER_OPERATORS } from '../generateGridState';
import { addDays, format } from 'date-fns';

const token = localStorage.getItem('backend_jwt') || '';
const userData: IUserDataToken | null = token.length ? jwt_decode(token) : null;

/**
 * Returns the type of operators to parse fot given definition
 *
 * @param fieldDef {IRecordField}
 * @param fieldName {String}
 */
export const getCustomFilterType = (fieldDef?: IRecordField, fieldName?: string) => {
    if (!fieldDef || !fieldName) return undefined;
    const { dataType, listType } = fieldDef;

    if (
        (dataType === 'dropDown' && USER_BASE_FIELDS.includes(fieldName as (typeof USER_BASE_FIELDS)[number])) ||
        (dataType === 'dropdown' && listType?.dataType === 'User')
    )
        return 'USER';

    if ((dataType === 'dropDown' && DATE_BASE_FIELDS.includes(fieldName as (typeof DATE_BASE_FIELDS)[number])) || dataType === 'date')
        return 'DATE';

    return undefined;
};

/**
 * Returns the value for given user operator
 *
 * @param value {String[]}
 * @param isBaseField {Boolean}
 * @param listValuesByField {ListValue[]}
 */
export const customUserOperatorsParser = (value: string[], isBaseField: boolean, listValuesByField: ListValue[]) => {
    if (!userData?.userId) {
        return value[0];
    }

    if (isBaseField) {
        return String(userData?.userId);
    }

    const listValueByUser = listValuesByField.find((el) => +el.userValue.id === +userData.userId);
    return listValueByUser?.id || value[0];
};

/**
 * Returns the value for given date operator
 *
 * @param value {String[]}
 */
export const customDateOperatorsParser = (value: string[]) => {
    const operator = getCustomFilterSelectedOperators(value[0]);
    if (operator === '@today') return new Date().toISOString();
    if (operator === '@yesterday') return format(addDays(new Date(), -1), 'MM/dd/yyyy');
    if (operator === '@lastweek') return format(addDays(new Date(), -7), 'MM/dd/yyyy');
    if (operator === '@last30days') return format(addDays(new Date(), -30), 'MM/dd/yyyy');

    return format(new Date(), 'MM/dd/yyyy');
};

/**
 * Returns the custom filter operator present in value
 *
 * @param value {String}
 */
const getCustomFilterSelectedOperators = (value: string): CUSTOM_FILTER_OPERATORS => {
    const formattedValue = value.toLowerCase().trim();

    if (formattedValue.includes('@username')) return '@username';
    if (formattedValue.includes('@lastweek')) return '@lastweek';
    if (formattedValue.includes('@yesterday')) return '@yesterday';
    if (formattedValue.includes('@last30days')) return '@last30days';

    return '@today';
};

/**
 * MUI Grid operators for each custom filtering operator if is diferent from the saved in Backoffice
 */
export const CUSTOM_FILTER_GRID_OPERATORS: Record<Extract<CUSTOM_FILTER_OPERATORS, '@last30days' | '@lastweek'>, string> = {
    '@last30days': FilterDateCriteriaEnum.isOnOrAfter,
    '@lastweek': FilterDateCriteriaEnum.isOnOrAfter
};
