import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { QUERY_GET_USER_DETAILS } from 'graphql/mutations/login';
import { GetUserDetails, UserLoginDetail } from 'views/pages/authentication/SelectTenant/types';

export type useMyTenantsProps = {
    onCompleted?: (data: UserLoginDetail[]) => void;
    immediate?: boolean;
};

export const useMyTenants = (options?: useMyTenantsProps) => {
    const [getUserDetails, { loading, data: tenantsData }] = useMutation<GetUserDetails>(QUERY_GET_USER_DETAILS, {
        onCompleted(data) {
            options?.onCompleted?.(data.getUserDetails || []);
        }
    });

    const tenantList = tenantsData?.getUserDetails ?? [];

    const getMyTenant = async () => {
        try {
            const { data } = await getUserDetails();
            const list = data?.getUserDetails || [];
            const tenantId = localStorage.getItem('tenant_id');
            if (!list.length || !tenantId) throw new Error('No tenants');

            const loggedTenant = list.map((el) => el.tenant).find((el) => +el.id === +tenantId);

            return loggedTenant ?? null;
        } catch (error) {
            console.log('error getting the tenant', error);
            return null;
        }
    };

    useEffect(() => {
        if (options?.immediate) getUserDetails();
    }, [getUserDetails, options?.immediate]);

    return { getUserDetails, loading, tenantList, getMyTenant };
};
