import { useMutation } from '@apollo/client';
import {
    MUTATION_REGISTER_LINE_ITEM,
    MUTATION_REGISTER_UPDATE_MANY_LINE_ITEMS,
    MUTATION_UPDATE_LINE_ITEM
} from 'graphql/mutations/lineItems';
import { QUERY_FIND_LINE_ITEMS } from 'graphql/queries/lineItems';
import {
    CreateLineItemsVariables,
    CreateOrUpdateManyLineItems,
    CreateOrUpdateManyLineItemsVariables,
    FindLineItemVariables,
    ICreateLineItems,
    IFindLineItems,
    ILineItems,
    IUpdateLineItems,
    UpdateLineItemsVariables
} from '../types';

export const useLineItemsMutations = (recordId: number) => {
    const [createOrUpdateByRow, { loading: loadingLineItemMutation }] = useMutation<
        CreateOrUpdateManyLineItems,
        CreateOrUpdateManyLineItemsVariables
    >(MUTATION_REGISTER_UPDATE_MANY_LINE_ITEMS, {
        update(cache, { data }) {
            const updated = data?.createOrUpdateManyLineItems;
            const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                query: QUERY_FIND_LINE_ITEMS,
                variables: {
                    data: {
                        recordHeadersIds: [recordId]
                    }
                }
            });
            if (!allFields || !updated) return;
            const allUpdatedLineItems: Record<string, ILineItems> = {};

            for (const item of updated) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { recordHeader, lineItemsByTypeFileds, ...rest } = item;
                allUpdatedLineItems[rest.id] = rest;
            }

            const newsLineItems = updated
                .filter((el) => !allFields.findLineItems.some((item) => +item.id === +el.id))
                .map(({ lineItemsByTypeFileds, recordHeader, ...rest }) => rest);

            const updatedLineItems = allFields.findLineItems.map((item) => {
                const itWasUpdated = allUpdatedLineItems[item.id];
                return itWasUpdated || item;
            });

            cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                query: QUERY_FIND_LINE_ITEMS,
                variables: {
                    data: {
                        recordHeadersIds: [recordId]
                    }
                },
                data: {
                    findLineItems: [...updatedLineItems, ...newsLineItems]
                }
            });
        }
    });

    const [createLineItem, { loading: loadingCreation }] = useMutation<ICreateLineItems, CreateLineItemsVariables>(
        MUTATION_REGISTER_LINE_ITEM,
        {
            update(cache, { data }) {
                const created = data?.createLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    }
                });
                if (!allFields || !created) return;

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    },
                    data: {
                        findLineItems: [...allFields.findLineItems, created]
                    }
                });
            }
        }
    );
    const [updateLineItem, { loading: loadingUpdate }] = useMutation<IUpdateLineItems, UpdateLineItemsVariables>(
        MUTATION_UPDATE_LINE_ITEM,
        {
            update(cache, { data }) {
                const updated = data?.updateLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    }
                });
                if (!allFields || !updated) return;

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    },
                    data: {
                        findLineItems: allFields.findLineItems.map((el) => (el.id === updated.id ? updated : el))
                    }
                });
            }
        }
    );

    const loading = loadingCreation || loadingUpdate || loadingLineItemMutation;

    return { createOrUpdateByRow, createLineItem, updateLineItem, loading };
};
