import { Grid, Typography } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';

export type PanelFieldSectionInlineProps = {
    title: string | JSX.Element;
    hideTitle?: boolean;
    noPadding?: boolean;
    isFocused?: boolean;
    labelStyle?: CSSProperties | null;
    isRequired?: boolean;
};

export const PanelFieldSectionInline = ({
    title,
    hideTitle,
    children,
    noPadding = false,
    isFocused = false,
    labelStyle = null,
    isRequired = false
}: PropsWithChildren<PanelFieldSectionInlineProps>) =>
    hideTitle ? (
        <>{children}</>
    ) : (
        <Grid item xs={12} sx={{ py: noPadding ? 0 : '10px' }}>
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    <Typography
                        sx={(theme) =>
                            labelStyle ?? {
                                paddingTop: '18px',
                                paddingLeft: '25px',
                                fontWeight: 600,
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                color: isFocused ? theme.palette.secondary[800] : '#616161'
                            }
                        }
                    >
                        {title} {isRequired && <span style={{ color: 'red' }}>*</span>}
                    </Typography>
                </Grid>
                <Grid item xs={8} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                    <Grid style={{ paddingTop: '18px', paddingLeft: '10px' }}>{children}</Grid>
                </Grid>
            </Grid>
        </Grid>
    );
