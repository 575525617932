// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconSettings } from '@tabler/icons';
import { OverrideIcon } from 'types';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconSettings
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export interface DashboardMenuProps {
    id: string;
    title: React.ReactNode | string;
    type: string;
    children: {
        id: string;
        title: React.ReactNode | string;
        type: string;
        url?: string;
        icon?: OverrideIcon;
        breadcrumbs?: boolean;
        children?: {
            id: string;
            title: React.ReactNode | string;
            type: string;
            url?: string;
            icon?: OverrideIcon;
            breadcrumbs?: boolean;
        }[];
    }[];
}

const dashboard: DashboardMenuProps[] = [
    {
        id: 'dashboard',
        title: <FormattedMessage id="dashboard" />,
        type: 'group',
        children: [
            {
                id: 'mainDashboard',
                title: <FormattedMessage id="main" />,
                type: 'item',
                url: 'dashboard',
                icon: icons.IconDashboard,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'catalogs',
        title: <FormattedMessage id="Catalogs" />,
        type: 'group',
        children: [
            {
                id: 'catalogs-group',
                title: <FormattedMessage id="Catalogs" />,
                type: 'collapse',
                breadcrumbs: false,
                children: [
                    {
                        id: 'catalogs-settings',
                        title: <FormattedMessage id="Catalogs" />,
                        type: 'item',
                        url: 'catalogs',
                        breadcrumbs: false
                    },
                    {
                        id: 'options-groups-settings',
                        title: <FormattedMessage id="Options Groups" />,
                        type: 'item',
                        url: 'options-groups-settings',
                        breadcrumbs: false
                    },
                    {
                        id: 'master-finishes-settings',
                        title: <FormattedMessage id="Master Finishes" />,
                        type: 'item',
                        url: 'master-finishes-settings',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
];

export default dashboard;
