import { Close, DeleteOutline, DragIndicator, ExpandMore, SaveOutlined, SystemUpdateAlt, TextRotateVertical } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridRowId
} from '@mui/x-data-grid-pro';

export type GridToolbarProps = {
    ToolbarButtons?: () => JSX.Element;
    rowReorder?: boolean;
    onSaveReorder?: () => void;
    toggleReorder?: () => void;
    onAlphabeticalOrder?: () => void;
    isReordering?: boolean;
    showQuickFilter?: boolean;
    handleShowImportDialog?: () => void;
    selectedRows?: GridRowId[];
    onDeleteSelected?: (selectedRows: GridRowId[]) => void;
    shouldEdit?: boolean;
    handleClickDelete?: () => void;
};

export const GridToolbar = ({
    ToolbarButtons,
    onSaveReorder,
    onAlphabeticalOrder,
    rowReorder,
    toggleReorder,
    isReordering,
    showQuickFilter,
    handleShowImportDialog,
    selectedRows,
    onDeleteSelected,
    shouldEdit,
    handleClickDelete
}: GridToolbarProps) => (
    <GridToolbarContainer>
        {showQuickFilter && (
            <GridToolbarQuickFilter
                variant="filled"
                size="small"
                placeholder="Find in current page"
                InputProps={{
                    disableUnderline: true,
                    sx: ({ palette }) => ({
                        borderRadius: '30px',
                        backgroundColor: palette.primary[300],
                        '&  input': { py: '10px' },
                        '&  .MuiSvgIcon-root ': { color: palette.primary[400] }
                    })
                }}
            />
        )}
        <GridToolbarColumnsButton
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            endIcon={<ExpandMore />}
        />
        <GridToolbarFilterButton
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            componentsProps={{ button: { endIcon: <ExpandMore /> } }}
        />
        <GridToolbarDensitySelector
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            endIcon={<ExpandMore />}
        />
        <GridToolbarExport
            sx={(theme: { palette: { primary: any[] } }) => ({
                color: theme.palette.primary[400],
                backgroundColor: theme.palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            endIcon={<ExpandMore />}
        />
        {handleClickDelete && (
            <Button
                onClick={handleClickDelete}
                sx={{
                    color: 'white',
                    backgroundColor: '#EF5350',
                    borderRadius: '30px',
                    pt: '8px',
                    pr: '14px',
                    '&:hover': { bgcolor: '#bf423f' },
                    '& .MuiButton-startIcon': { mx: '2px' }
                }}
                startIcon={<DeleteOutline />}
                size="medium"
                color="primary"
            >
                Delete
            </Button>
        )}
        {handleShowImportDialog && shouldEdit && (
            <Button
                onClick={handleShowImportDialog}
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px'
                })}
                startIcon={<SystemUpdateAlt sx={{ marginLeft: '4px !important' }} />}
                size="medium"
                color="primary"
            >
                Import
            </Button>
        )}
        {ToolbarButtons && <ToolbarButtons />}
        {!!rowReorder && isReordering && (
            <>
                {onAlphabeticalOrder && (
                    <Button
                        onClick={onAlphabeticalOrder}
                        sx={({ palette }) => ({
                            color: palette.primary[400],
                            backgroundColor: palette.primary[300],
                            borderRadius: '30px',
                            padding: '8px'
                        })}
                        startIcon={<TextRotateVertical />}
                        size="medium"
                        color="primary"
                    >
                        Alphabetical Order
                    </Button>
                )}
                <Button
                    onClick={onSaveReorder}
                    sx={({ palette }) => ({
                        borderRadius: '30px',
                        padding: '8px'
                    })}
                    startIcon={<SaveOutlined />}
                    size="medium"
                    color="secondary"
                    variant="contained"
                >
                    Save Order
                </Button>
                <Button
                    onClick={toggleReorder}
                    sx={({ palette }) => ({
                        borderRadius: '30px',
                        padding: '8px'
                    })}
                    startIcon={<Close />}
                    size="medium"
                    color="error"
                    variant="contained"
                >
                    Cancel Order
                </Button>
            </>
        )}
        {!!rowReorder && !isReordering && (
            <Button
                onClick={toggleReorder}
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px'
                })}
                startIcon={<DragIndicator />}
                size="medium"
                color="primary"
            >
                Edit order
            </Button>
        )}
        {!!onDeleteSelected && !!selectedRows?.length && (
            <Button
                onClick={() => onDeleteSelected(selectedRows)}
                color="error"
                startIcon={<DeleteOutline sx={{ marginLeft: '4px !important' }} />}
                size="medium"
                variant="contained"
                sx={{
                    borderRadius: '30px',
                    padding: '8px'
                }}
            >
                Delete
            </Button>
        )}
    </GridToolbarContainer>
);
