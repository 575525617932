import { useRef } from 'react';
import { LineItemsGridRef, LineItemsGrid, LineItemsGridProps } from './Grid';
import { Fab, Grid } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

export const LineItemsRecordView = ({ isOpen, recordId, headers, recordTypeId, showCustomNoRowsOverlay }: LineItemsGridProps) => {
    const lineItemGridRef = useRef<LineItemsGridRef | null>(null);

    const handleClickAdd = () => {
        lineItemGridRef.current?.handleAddClick();
    };

    return (
        <Grid container sx={{ height: 'calc(100% - 52px)' }}>
            <Grid item xs={12} container spacing={1} height="100%" sx={{ position: 'relative' }}>
                <Fab
                    size="small"
                    sx={{ position: 'absolute', boxShadow: 'none !important', bgcolor: '#F5F6F7 !important', right: 0, top: '10px' }}
                    onClick={handleClickAdd}
                >
                    <AddCircleOutline htmlColor="#858585" />
                </Fab>
                <LineItemsGrid
                    isOpen={isOpen}
                    recordId={recordId}
                    headers={headers}
                    recordTypeId={recordTypeId}
                    showCustomNoRowsOverlay={showCustomNoRowsOverlay}
                    ref={lineItemGridRef}
                />
            </Grid>
        </Grid>
    );
};
